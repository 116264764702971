<template>
  <div
    class="headline"
    :class="[
      {'headline--short': !props.subtitle},
      {'headline--dark': props.variant === 'dark'},
      {'headline--featured': props.variant === 'featured'}]"
  >
    <div class="headline__content">
      <h1
        class="headline__title"
        :class="{'headline__title--bigger': props.titleVariant === 'bigger'}"
      >{{ title }}</h1>
      <template v-if="props.subtitle">
        <Divider
          size="lg"
        />
        <h2 v-html="props.subtitle"></h2>
      </template>
      <div>
        <slot></slot>
      </div>
    </div>
    <div class="headline__bottom" v-if="props.thirdText">
      <h3>{{ props.thirdText }}</h3>
    </div>
    <video
      v-if="video"
      ref="videoPlayer"
      :src="backgroundUrl"
      class="headline__background"
      loading="lazy"
      autoplay
      loop
      muted
    ></video>
    <img
      v-else
      :src="backgroundUrl"
      class="headline__background"
      loading="lazy"
    >
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  variant: {
    type: String,
    validator: value => {
      return ['light', 'dark', 'featured'].includes(value)
    },
    default: 'light'
  },
  subtitle: String,
  thirdText: String,
  image: {
    type: String,
    default: 'default'
  },
  video: String,
})

const videoPlayer = ref(null)
onMounted(() => {
  if (videoPlayer.value) {
    videoPlayer.value.play()
  }
})

const backgroundFile = props.video ? `${props.video}.mp4` : `${props.image}.jpg`
const backgroundUrl = `/backgrounds/${backgroundFile}`

</script>
<style scoped>
.headline {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-900);
}
.headline__content {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  padding: calc(var(--header-height) + 8vh) var(--gap) 0;
  text-align: center;
}
.headline--short .headline__content{
  min-height: 50vh;
}
.headline__content > * + * {
  margin-top: var(--gap);
}
.headline__title {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  font-size: clamp(2rem, 10vw, 9rem);
  font-weight: bold;
  color: #FFF;
  opacity: .8;
  letter-spacing: .8rem;
  text-shadow: 1px 1px .5rem rgba(0,0,0,.4);
}
.headline--featured .headline__title {
  max-width: 100rem;
  font-size: clamp(2rem, 10vw, 15rem);
  color: #FFF;
  line-height: 1;
}

h2 {
  color: #FFF;
  font-style: italic;
  font-size: 2.2rem;
  font-size: clamp(2rem, 6vw, 3rem);
  font-weight: 300;
  text-shadow: 1px 1px .3rem rgba(0,0,0,0.3);
}
.headline__bottom {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  background-color: rgba(0, 0, 0, .9);
  opacity: .8;
  padding: 0 var(--gap);
}
h3 {
  max-width: 84rem;
  color: #FFF;
  font-weight: 400;
  font-size: clamp(2rem, 6vw, 4rem);
  text-align: center;
}
.headline__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  filter: blur(.2rem);
  opacity: .75;
}
@media (max-width: 400px) {
  .headline {
    --header-height: 12rem;
  }
}
</style>